<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" width="50%">
      <v-list-item>
        <v-list-item-title class="title">
          {{ $t("クリニックCOM") }}
        </v-list-item-title>
        <v-btn icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <v-list nav>
        <v-list-item v-for="menu in menus" :key="menu.title" :to="menu.url">
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app absolute>
      <!-- アプリケーションバー -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon to="/about">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <LocaleSwitcher />
    </v-app-bar>
    <base-view />

    <v-footer>
      <!-- フッター -->
    </v-footer>
  </v-app>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher";

import i18n from "@/i18n";

export default {
  name: "BaseLayout",
  components: {
    BaseView: () => import("./View"),
    LocaleSwitcher,
  },

  data: () => ({
    drawer: false,
    color: "blue",
    patient_id: "",
    menus: [
      { title: i18n.tc("menu.home"), icon: "mdi-home", url: "/" },
      { title: i18n.tc("menu.購入履歴"), icon: "mdi-cart", url: "/history" },
      {
        title: i18n.tc("menu.ご利用案内"),
        icon: "mdi-information-variant",
        url: "/notice",
      },
    ],
  }),
};
</script>
